import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestAnalyticsArchive, requestLayoutsList } from "store/rext";
import { getArchiveAnalyticState, getLayoutListData } from "store/selectors";
import FilterContainer, { DEFAULT_VALUE } from "./Filter";
import classNames from "classnames";
import { AnalyticsEvents, Events, formatDate, LayoutName } from "./utils";
import { TemplateTypeOptions } from "./DownloadLayoutAnalytics";

import "./style.scss";
import Loading from "components/Loading";

function DowloandPPTContainer() {
  const LayoutHeaders = Object.keys(LayoutName).sort((a, b) => a.localeCompare(b)) as Array<keyof typeof LayoutName>;
  const dispatch = useDispatch();
  const { data: archiveAnalyticData, fetching: archiveAnalyticFetching } = useSelector(getArchiveAnalyticState);
  const { data: layoutListData, fetching: layoutFetching } = useSelector(getLayoutListData);

  const handleOnSubmit = (filterValue: any) => {
    dispatch(requestAnalyticsArchive(filterValue));
    dispatch(requestLayoutsList({ templateTypeId: filterValue.templateTypeId }));
  };
  const layoutNames = useMemo(() => {
    return layoutListData?.map((layout: any) => layout.name).sort() || [];
  }, [layoutListData]);


  return (
    <Fragment>
      <FilterContainer handleFilterChange={handleOnSubmit} showTemplateType={true} />
      <div className="width-100 flex flex-justify-center flex-align-center">
        {archiveAnalyticFetching && layoutFetching && <Loading />}
        {!archiveAnalyticFetching && !layoutFetching && archiveAnalyticData?.length > 0 && layoutListData?.length > 0 && (
          <div className="width-98 height-100 analytics-custom-table">
            <table className="">
              <thead>
                <tr>
                  <th rowSpan={2}>Date</th>
                  <th rowSpan={2}>PPT Count</th>
                  <th rowSpan={2}>Downloaded</th>
                  <th rowSpan={2}>Public GPT</th>
                  <th rowSpan={2}>Guest PPT</th>
                  <th colSpan={AnalyticsEvents.length} style={{ textAlign: "center" }}>
                    Event Counts
                  </th>
                  <th colSpan={layoutNames.length} style={{ textAlign: "center" }}>
                    Layout Distribution
                  </th>
                  <th>Total Slides</th>
                </tr>
                <tr>
                  {AnalyticsEvents.map((event, index) => (
                    <th key={event.name} className="analytics-sub-header">
                      {event.name}
                    </th>
                  ))}
                  {layoutNames.map((name: string, index: number) => (
                    <th key={index} className="analytics-sub-header">
                      {name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {archiveAnalyticData?.length > 0 ? (
                  archiveAnalyticData.map((record: any) => (
                    <Fragment key={record._id}>
                      <tr>
                        <td>{formatDate(record.date) || "-"}</td>
                        <td>{record.downloadPPT || "0"}</td>
                        <td>{record.totalPPT || "0"}</td>
                        <td>{record.publicGPT || "0"}</td>
                        <td>{record.guestPPT || "0"}</td>
                        {AnalyticsEvents.map((event) => (
                          <td key={event.name}>
                            {record.events[`${event.event_name_start}`] || "0"} | {record.events[`${event.event_name_end}`] || "0"}
                          </td>
                        ))}
                        {layoutNames?.map((layoutName: any) => {
                          const layoutData = record?.layouts[layoutName] || {};
                          const count = layoutData.count || 0;
                          const percentage = layoutData.percentage !== undefined ? `${layoutData.percentage}%` : "0%";
                          return (
                            <td key={layoutName}>
                              {count} | {percentage}
                            </td>
                          );
                        })}
                        <td>{record.totalSlides || 0}</td>
                      </tr>
                    </Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={AnalyticsEvents.length + LayoutHeaders.length + 5}>No records available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default DowloandPPTContainer;
