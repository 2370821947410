// interface for createSlide
export interface ISlide {
  slideName: string;
  metaType: string;
  optionsMeta: string;
  chatMeta: string;
  sequence: number;
}

export interface IColorOptions {
  Color1: string;
  Color2: string;
  Color3: string;
  Color4: string;
  Color5: string;
  Color6: string;
  Color7: string;
  Color8: string;
  Color9: string;
  Color10: string;
}

export enum Align_Positions {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center"
}

export const DEFAULT_THEME_COLOR: IColorOptions = {
  Color1: "color1",
  Color2: "color2",
  Color3: "color3",
  Color4: "color4",
  Color5: "color5",
  Color6: "color6",
  Color7: "color7",
  Color8: "color8",
  Color9: "color9",
  Color10: "color10"
};

export enum MetaTypeOptions {
  IMAGE = "image",
  SHAPE = "shape",
  TEXT = "text",
  TABLE = "table",
  CHART = "chart",
  LIST = "list",
  GRADIENT = "gradient"
}

export enum TableTypes {
  DEFAULT = "deafult",
  PIVOT = "pivot",
  PROGRESS = "progress"
}

export enum TableStyles {
  DEFAULT = "default",
  CUSTOM = "custom"
}

export enum Arrowtypes {
  ARROW = "arrow",
  DIAMOND = "diamond",
  OVAL = "oval",
  STEALTH = "stealth",
  TRIANGLE = "triangle",
  NONE = "none"
}

export enum ChartType {
  AREA = 'area',
  BAR = 'bar',
  BAR3D = 'bar3D',
  BUBBLE = 'bubble',
  DOUGHNUT = 'doughnut',
  LINE = 'line',
  PIE = 'pie',
  RADAR = 'radar',
  SCATTER = 'scatter',
  SLIDER = 'slider',
  SPEEDOMETER = 'speedometer'
}

export enum lineDataSymbols {
  CIRCLE = "circle",
  DASH = "dash",
  DIAMOND = "diamond",
  DOT = "dot",
  NONE = "none",
  SQUARE = "square",
  TRIANGLE = "triangle",
}

export enum DashType {
  LGDASH = "lgDash",
  DASHDOT = "dashDot",
  DASH = "dash",
}

enum axisLineStyle {
  DASH = "dash",
  DOT = "dot",
  SOLID = "solid",
  NONE = "none"
}

enum borderTypes {
  DASH = "dash",
  SOLID = "solid",
  NONE = "none"
}

export enum LegendPositions {
  RIGHT = "r",
  LEFT = "l",
  BOTTOM = "b",
  TOP = "t",
  TOP_RIGHT = "tr"
}

enum alignmentPosition {
  MIDDLE = "middle",
  BOTTOM = "bottom",
  TOP = "top",
}
export enum FontFace {
  ARIAL = "Arial",
  ARIAL_NOVA = "Arial Nova",
  ARIAL_NOVA_COND = "Arial Nova Cond",
  CENTURY_GOTHIC = "Century Gothic",
  TW_CEN_MT = "Tw Cen MT",
  ARIAL_ROUNDED = "Arial Rounded MT Bold",
  ARIAL_NOVA_LIGHT = "Arial Nova Light (Body)",
  CASCADIA_CODE = "Cascadia Code SemiBold",
  UNIVERS_CONDENSED_LIGHT= "Univers Condensed Light",
  WALBAUM_DISPLAY_LIGHT = "Walbaum Display Light"
}

export enum GradientShape {
  ELLIPSE = "ellipse",
  RECTANGLE = "rectangle"
}

export enum GradientDirection {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
  BOTH = "both"
}

export const DEFAULT_VALUES = {
  name: "",
  postfix: "",
  rules: "",
  stage:"",
  category: "",
  isDefault:"",
  layers: [{ metaType: "", chatMeta: {}, optionsMeta: {} }],
};

export const defaultMetaTypeOptions = [
  { label: "Select", value: "" },
  { label: "IMAGE", value: MetaTypeOptions.IMAGE },
  { label: "SHAPE", value: MetaTypeOptions.SHAPE },
  { label: "TEXT", value: MetaTypeOptions.TEXT },
  { label: "TABLE", value: MetaTypeOptions.TABLE },
  { label: "LIST", value: MetaTypeOptions.LIST },
  { label: "CHART", value: MetaTypeOptions.CHART },
  { label: "GRADIENT", value: MetaTypeOptions.GRADIENT }
];

export const colorOptions = [
  { label: "Select", value: "" },
  { label: "Accent 1", value: DEFAULT_THEME_COLOR.Color1 },
  { label: "Accent 2" , value: DEFAULT_THEME_COLOR.Color2 },
  { label: "Accent 3", value: DEFAULT_THEME_COLOR.Color3 },
  { label: "Accent 4", value: DEFAULT_THEME_COLOR.Color4 },
  { label: "Accent 5", value: DEFAULT_THEME_COLOR.Color5 },
  { label: "Accent 6" , value: DEFAULT_THEME_COLOR.Color6 },
  { label: "Text 2", value: DEFAULT_THEME_COLOR.Color7 },
  { label: "Background 2", value: DEFAULT_THEME_COLOR.Color8 },
  { label: "Text 1", value: DEFAULT_THEME_COLOR.Color9 },
  { label: "Background 1", value: DEFAULT_THEME_COLOR.Color10 }
];

export const fontFaceOptions = [
  { label: "Select", value: undefined },
  { label: "Arial", value: FontFace.ARIAL },
  { label: "Arial Nova", value: FontFace.ARIAL_NOVA },
  { label: "Arial Nova Cond", value: FontFace.ARIAL_NOVA_COND },
  { label: "Arial Nova Light (Body)", value: FontFace.ARIAL_NOVA_LIGHT },
  { label: "Arial Rounded MT Bold", value: FontFace.ARIAL_ROUNDED },
  { label: "Cascadia Code SemiBold", value: FontFace.CASCADIA_CODE },
  { label: "Century Gothic", value: FontFace.CENTURY_GOTHIC },
  { label: "Tw Cen MT", value: FontFace.TW_CEN_MT },
  { label: "Univers Condensed Light", value: FontFace.UNIVERS_CONDENSED_LIGHT },
  { label: "Walbaum Display Light", value: FontFace.WALBAUM_DISPLAY_LIGHT }
];

export const borderTypeOptions = [
  { label: "Select", value: "" },
  { label: "None", value: borderTypes.NONE },
  { label: "Solid", value: borderTypes.SOLID },
  { label: "Dash", value: borderTypes.DASH },
];

export const VAlignOptions = [
  { label: "Select", value: undefined },
  { label: "Top", value: alignmentPosition.TOP },
  { label: "Middle", value: alignmentPosition.MIDDLE },
  { label: "Bottom", value: alignmentPosition.BOTTOM},
];

export const alignOptions = [
  { label: "Select", value: undefined },
  { label: "RIGHT", value: Align_Positions.RIGHT },
  { label: "LEFT", value: Align_Positions.LEFT },
  { label: "CENTER", value: Align_Positions.CENTER }
];

export const ChartTypeOptions = [
  { label: "Select", value: "" },
  { label: "AREA", value: ChartType.AREA },
  { label: "BAR", value: ChartType.BAR },
  { label: "BAR3D", value: ChartType.BAR3D },
  { label: "BUBBLE", value: ChartType.BUBBLE },
  { label: "LINE", value: ChartType.LINE },
  { label: "PIE", value: ChartType.PIE },
  { label: "DOUGHNUT", value: ChartType.DOUGHNUT },
  { label: "RADAR", value: ChartType.RADAR },
  { label: "SCATTER", value: ChartType.SCATTER },
  { label: "SLIDER", value: ChartType.SLIDER },
  { label: "SPEEDOMETER", value: ChartType.SPEEDOMETER }
];

export const dataSymbolOptions = [
  { label: "NONE", value: lineDataSymbols.NONE },
  { label: "CIRCLE", value: lineDataSymbols.CIRCLE },
  { label: "DIAMOND", value: lineDataSymbols.DIAMOND },
  { label: "DOT", value: lineDataSymbols.DOT },
  { label: "DASH", value: lineDataSymbols.DASH },
  { label: "TRIANGLE", value: lineDataSymbols.TRIANGLE },
];

export const legendPositionOptions = [
  { label: "Select", value: undefined },
  { label: "BOTTOM", value: LegendPositions.BOTTOM },
  { label: "LEFT", value: LegendPositions.LEFT },
  { label: "RIGHT", value: LegendPositions.RIGHT },
  { label: "TOP", value: LegendPositions.TOP },
  { label: "TOP_RIGHT", value: LegendPositions.TOP_RIGHT },
];

export const axisLineStyleOptions = [
  { label: "Select", value: undefined },
  { label: "None", value: axisLineStyle.NONE },
  { label: "DOT", value: axisLineStyle.DOT },
  { label: "Solid", value: axisLineStyle.SOLID },
  { label: "Dash", value: axisLineStyle.DASH },
];

export const ArrowTypeOptions = [
  { label: "NONE", value: Arrowtypes.NONE },
  { label: "ARROW", value: Arrowtypes.ARROW },
  { label: "DIAMOND", value: Arrowtypes.DIAMOND },
  { label: "OVAL", value: Arrowtypes.OVAL },
  { label: "STEALTH", value: Arrowtypes.STEALTH },
  { label: "TRIANGLE", value: Arrowtypes.TRIANGLE },
];

export const DashTypeOptions = [
  { label: "Select", value: undefined },
  { label: "LGDASH", value: DashType.LGDASH },
  { label: "DASH", value: DashType.DASH },
  { label: "DASHDOT", value: DashType.DASHDOT }
];

export const gradientShapeOptions = [
  { label: "Select", value: undefined },
  { label: "Ellipse", value: GradientShape.ELLIPSE },
  { label: "Rectangle", value: GradientShape.RECTANGLE }
];

export const gradientDirectionOptions = [
  { label: "Select", value: undefined },
  { label: "Horizontal", value: GradientDirection.HORIZONTAL },
  { label: "Vertical", value: GradientDirection.VERTICAL },
  { label: "Both", value: GradientDirection.BOTH }
];

export const appNameOptions = [
  { label: "Select", value: undefined },
  { label: "Slide Team", value: "APP_SLIDETEAM" },
  { label: "Slide Geeks", value: "APP_SLIDEGEEKS" },
];
