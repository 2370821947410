 export enum LayoutName {
  OVERVIEW = "Overview",
  MATRIX = "Matrix",
  GANTT_CHART = "Gantt Chart",
  CONTACT_INFORMATION = "Contact Information",
  CIRCULAR_PROCESS = "Circular Process",
  DASHBOARD = "Dashboard",
  PORTFOLIO = "Portfolio",
  VISION = "Vision",
  LINEAR_PROCESS = "Linear Process",
  TEAM_INTRODUCTION = "Team Introduction",
  TESTIMONIAL = "Testimonial",
  CASE_STUDY = "Case Study",
  CHECKLIST = "Checklist",
  FUNNEL = "Funnel",
  COVER = "Cover",
  BULLET_POINTS = "Bullet Points",
  GRAPH_BAR = "Graph Bar",
  HIERARCHY_NON_PEOPLE = "Hierarchy NonPeople",
  CONCLUSION = "Conclusion",
  SWOT = "SWOT",
  TIMELINE = "Timeline",
  PYRAMID_STRUCTURE = "Pyramid Structure",
  SURVEYS = "Surveys",
  WORLD_MAP = "World Map"
}


export enum Events {
  REGENERATE_STARTED = "REGENERATE_STARTED",
  REGENERATE_END = "REGENERATE_END",
  COLOR_CHANGE_STARTED = "COLOR_CHANGE_STARTED",
  COLOR_CHANGE_END = "COLOR_CHANGE_END",
  TEMPLATE_CHANGE_STARTED = "TEMPLATE_CHANGE_STARTED",
  TEMPLATE_CHANGE_END = "TEMPLATE_CHANGE_END",
  DOWNLOAD_STARTED = "DOWNLOAD_STARTED",
  DOWNLOAD_END = "DOWNLOAD_END",
  TEMPLATE_SELECTED = "TEMPLATE_SELECTED",
  CREATE_NEW_SLIDE_STARTED = "CREATE_NEW_SLIDE_STARTED",
  CREATE_NEW_SLIDE_END = "CREATE_NEW_SLIDE_END",
  INSERT_NEW_SLIDE_STARTED = "INSERT_NEW_SLIDE_STARTED",
  INSERT_NEW_SLIDE_END = "INSERT_NEW_SLIDE_END",
  DELETE_SLIDE_START = "DELETE_SLIDE_START",
  DELETE_SLIDE_END = "DELETE_SLIDE_END",
  MOVE_SLIDE_START = "MOVE_SLIDE_START",
  MOVE_SLIDE_END = "MOVE_SLIDE_END",
}

export const AnalyticsEvents = [
  {
    name: "Color",
    event_name_start: "COLOR_CHANGE_STARTED",
    event_name_end: "COLOR_CHANGE_END"
  },
  {
    name: "Template",
    event_name_start: "TEMPLATE_CHANGE_STARTED",
    event_name_end: "TEMPLATE_CHANGE_END"

  },
  {
    name: "Regenerate",
    event_name_start: "REGENERATE_STARTED",
    event_name_end: "REGENERATE_END"
  },
  {
    name: "Create",
    event_name_start: "CREATE_NEW_SLIDE_STARTED",
    event_name_end: "CREATE_NEW_SLIDE_END"
  },
  {
    name: "Insert",
    event_name_start: "INSERT_NEW_SLIDE_STARTED",
    event_name_end: "INSERT_NEW_SLIDE_END"
  },
  {
    name: "Move",
    event_name_start: "MOVE_SLIDE_START",
    event_name_end: "MOVE_SLIDE_END"
  },
  {
    name: "Delete",
    event_name_start: "DELETE_SLIDE_START",
    event_name_end: "DELETE_SLIDE_END"
  }
]

 export function formatDate(isoString:any) {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, '0'); 
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const year = date.getFullYear();
  return `${day}-${month}-${year}`; 
}
