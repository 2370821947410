import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { HookFormInput, HookFormSelect } from "components/FormInputs";
import CustomButton from "components/CustomButton";
import { appNameOptions } from "containers/SlidesContainer/utils";
import { requestTemplateTypeList } from "store/rext";
import { useDispatch, useSelector } from "react-redux";
import { getTempTypeListData } from "store/selectors";
import { hookformRequired } from "utils/FormValidations";

interface IFilterValues {
  startDate: string;
  endDate: string;
  appName: string;
  templateTypeId: string;
}

const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 7);

const startFormattedDate = startDate.toISOString().split("T")[0];
const endFormattedDate = endDate.toISOString().split("T")[0];

export const DEFAULT_VALUE: IFilterValues = {
  startDate: startFormattedDate,
  endDate: endFormattedDate,
  appName: "",
  templateTypeId: "",
};

const requiredValidation: any = {
  ...hookformRequired("Required"),
};

function FilterContainer({ handleFilterChange , showTemplateType}: any) {
  const dispatch = useDispatch();
  const { data: listData, fetching: listFetching } = useSelector(getTempTypeListData);



  const templateTypeOptions = [
    { label: "Select", value: undefined },
    ...listData.map((item: any) => ({
      label: item.name,
      value: item._id,
    })),
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: useMemo(
      () => ({
        startDate: `${DEFAULT_VALUE.startDate}`,
        endDate: `${DEFAULT_VALUE.endDate}`,
        appName: "",
        templateTypeId: "",
      }),
      []
    ),
  });

  const handleOnSubmit = (data: IFilterValues) => {
    const { startDate, endDate } = data;
    const transformedData = {
      startDate: `${startDate}T00:00:00-08:00`,
      endDate: `${endDate}T23:59:59-08:00`,
      appName: data.appName,
      templateTypeId: data.templateTypeId,
    };
    handleFilterChange(transformedData);
  };


  useEffect(() => {
    dispatch(requestTemplateTypeList({}));
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-row flex-align-center flex-justify-between">
      <HookFormInput
        control={control}
        errors={errors}
        type="date"
        name="startDate"
        id="startDate"
        label="Start Date"
        baseClassName="width-25 margin-l-3"
        defaultValue={DEFAULT_VALUE.startDate}
        onChange={(event: any) => setValue("startDate", event.target.value)}
      />
      <HookFormInput
        control={control}
        errors={errors}
        type="date"
        name="endDate"
        id="endDate"
        label="End Date"
        baseClassName="width-25 margin-l-3"
        defaultValue={DEFAULT_VALUE.endDate}
        onChange={(event: any) => setValue("endDate", event.target.value)}
      />
      <HookFormSelect
        control={control}
        errors={errors}
        validation={requiredValidation}
        options={appNameOptions}
        id={"appName"}
        name={"appName"}
        label={"Choose App"}
        baseClassName="width-10 margin-l-3"
      />
      {showTemplateType && (
      <HookFormSelect
        control={control}
        errors={errors}
        validation={requiredValidation}
        options={templateTypeOptions}
        id={"templateTypeId"}
        name={"templateTypeId"}
        label={"Choose Template"}
        baseClassName="width-12 margin-l-3"
      />
      )}
      <div className="padding-5">
        <CustomButton buttonText="Search" type="submit" primaryButton round/>
      </div>
    </form>
  );
}

export default FilterContainer;
