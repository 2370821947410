import { useEffect, useMemo, forwardRef, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { hookformRequired } from "utils/FormValidations";
import CustomButton from "components/CustomButton/CustomButton";
import { HookFormCheckbox, HookFormInput } from "components/FormInputs";
import LayerForm from "./LayerForm";
import { DEFAULT_VALUES, defaultMetaTypeOptions } from "./utils";
import { HookFormTextarea } from "components/FormInputs/HookFormTextarea";
import "./style.scss";

const requiredValidation = {
  ...hookformRequired("Required"),
};

interface Props {
  activeSlide?: any;
  getdata?: (data: any) => void;
  initialData: any;
}

function SlideForm(props: Props, ref: any) {
  const { activeSlide, getdata, initialData } = props;
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => {
      return initialData || DEFAULT_VALUES;
    }, [initialData]),
  });

  const onSubmit = (data: any) => {
    console.log(data);
    if (getdata) {
      getdata(data);
    }
  };

  useEffect(() => {
    if (activeSlide) {
      reset(initialData);
    } else {
      reset(DEFAULT_VALUES);
    }
  }, [reset, activeSlide]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className="height-100 position-relative">
        <div className="text-5 text-bold padding-l-4 padding-t-2 text-align-center">{activeSlide ? "UPDATE SLIDE" : "Create New Slide"}</div>
        <div className="flex flex-row">
          <HookFormInput
            validation={requiredValidation}
            control={control}
            errors={errors}
            id="slideName"
            name="name"
            label="Slide Name"
            placeholder="Enter Slide Name"
            baseClassName="width-25 padding-l-4"
            labelClassName="padding-t-2"
          />
          <HookFormTextarea id="postfix" name="postfix" label="Postfix" control={control} errors={errors} rows={2} baseClassName="width-40 padding-l-4" />
          <HookFormTextarea id="rules" name="rules" label="Rules" control={control} errors={errors} rows={2} baseClassName="width-40 padding-l-4 margin-r-4" />
        </div>
        <div className="flex">
          <HookFormInput
            validation={{}}
            control={control}
            errors={errors}
            id="stage"
            name="stage"
            label="Stage"
            placeholder="Enter Stage"
            baseClassName="width-25 padding-l-4"
            labelClassName="padding-t-2"
          />
          <HookFormInput
            validation={{}}
            control={control}
            errors={errors}
            id="category"
            name="category"
            label="category"
            placeholder="Enter Category"
            baseClassName="width-25 padding-l-4"
            labelClassName="padding-t-2"
          />
          <HookFormCheckbox
            validation={{}}
            control={control}
            errors={errors}
            baseClassName="margin-l-3"
            label={"Make Default"}
            id={"isDefault"}
            name={"isDefault"}
          />
        </div>
        <LayerForm
          validation={{}}
          control={control}
          errors={errors}
          name="layers"
          watch={watch}
          reset={reset}
          ref={ref}
          setValue={setValue}
          defaultOptions={defaultMetaTypeOptions}
        />
        <div className="flex flex-align-center flex-justify-center margin-t-2">
          <CustomButton
            type="submit"
            buttonText={activeSlide ? "Update" : "Create"}
            gradientButton
            // disabled={slideFetching}
            baseclassname={"padding-6 cursor-pointer"}
            buttonTextClass={"text-5"}
          />
        </div>
      </form>
    </Fragment>
  );
}

export default forwardRef(SlideForm);
