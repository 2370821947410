import CustomTable, { IColumns, ISortingCriteria } from 'components/CustomTableComponent/CustomTable';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestPPTHistory, requestPPTPrompt } from 'store/rext';
import { getPPTHistoryState, getPPTPromptState } from 'store/selectors';
import { convertDateToPST } from './utils';
import DownloadLink from 'components/Download/DownLoadLink';

const columns: IColumns[] = [
  {
    key: "label",
    header: "Label",
    sort: false,
   
  },
  {
    key: "link",
    header: "Prompt",
    sort: false,
    customValue: (data: any) => <a href={data.link}>Prompt Download</a>
  },

  {
    key: "resultLink",
    header: "Response",
    sort: false,
    customValue: (data: any) => <a href={data.resultLink}>Response Download</a>
  },
  
];

function PPTPrompt() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const { data: promptData, fetching: promptFetching } = useSelector(getPPTPromptState);
 
  useEffect(()=> {
    dispatch(requestPPTPrompt({execution_id : id}))
  }, [])
  
  return (
    <CustomTable
    tableData={promptData.records|| []}
    columns={columns}
    hasPagination={false}
    fetching={promptFetching || false} 
    sortTable
    />
    
  )
}

export default PPTPrompt