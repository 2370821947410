import { Fragment, useMemo, useState } from "react";
import { Pagination } from "semantic-ui-react";
import Loading from "components/Loading";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import CustomButton from "components/CustomButton";
import "./style.scss";
import classNames from "classnames";

export interface IColumns {
  key: string;
  header: string;
  customValue?: any;
  sort?: boolean;
  sortKey?: string;
}

export interface ISortingCriteria {
  sortBy: string;
  sortOrder: string;
}

export interface IActionButtons {
  label: string;
  icon?: keyof typeof CUSTOM_SVG_ICON;
  iconColor?: string;
  handleClick: (rowData: any) => void;
}

interface ICustomTable {
  columns: IColumns[];
  // sortByKey?: string;
  tableData: any[];
  paginationData?: any;
  hasPagination: boolean;
  fetching: boolean;
  actionButtons?: Array<IActionButtons>;
  handlePaginationChange?: any;
  currentPage?: any;
  sortTable?: boolean;
  sortingCriteria?: ISortingCriteria;
  onSortChange?: (criteria: ISortingCriteria) => void;
  showActionButtonsConditionally?: boolean;
  children?: any;
  fetchData?: any;
  serverSideSorting?: boolean;
  baseClassName?:string;
  customTableClassName?:string;
}

function CustomTable(props: ICustomTable) {
  const {
    columns,
    // sortByKey,
    tableData,
    paginationData,
    hasPagination,
    fetching,
    actionButtons,
    handlePaginationChange,
    currentPage,
    sortTable,
    showActionButtonsConditionally,
    children,
    sortingCriteria,
    fetchData,
    serverSideSorting,
    onSortChange,
    baseClassName,
    customTableClassName
  } = props;

  const handleSortColumn = (sortKey: string) => {
    if (serverSideSorting) {
      fetchData({
        sortBy: sortKey,
        sortOrder: sortKey === sortingCriteria?.sortBy ?  sortingCriteria?.sortOrder === "ASC" ? "DESC" : "ASC" : "DESC",
      });
    } else {
      if (
        sortTable &&
        sortingCriteria &&
        typeof props.onSortChange === "function"
      ) {
        if (sortKey === sortingCriteria.sortBy) {
          props.onSortChange({
            ...sortingCriteria,
            sortOrder: sortingCriteria.sortOrder === "ASC" ? "DESC" : "ASC",
          });
        } else {
          props.onSortChange({ sortBy: sortKey, sortOrder: "DESC" });
        }
      }
    }
  };

  const sortData = (data: any[], sortingCriteria: any) => {
    const { sortBy, sortOrder } = sortingCriteria || {};
    const sortedData = [...data];

    sortedData.sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];

      if (valueA < valueB) {
        return sortOrder === "ASC" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === "ASC" ? 1 : -1;
      }
      return 0;
    });

    return sortedData;
  };

  const handleAction = (action: IActionButtons, rowData: any) => {
    if (typeof action.handleClick === "function") {
      action.handleClick(rowData);
    } else {
      console.error("action.onClick is not a function");
    }
  };

  const renderColumnHeader = (column: IColumns) => {
    const isSortable = (sortTable || serverSideSorting) && column.sort;
    const isSorted = sortingCriteria?.sortBy === column.sortKey;
    const arrowIcon = isSortable ? (
      isSorted ? (
        sortingCriteria?.sortOrder === "ASC" ? (
          <SvgIcon
            svgType={SVGType.SEMANTIC}
            name={"arrow up"}
            size={"small"}
          />
        ) : (
          <SvgIcon
            svgType={SVGType.SEMANTIC}
            name={"arrow down"}
            size={"small"}
          />
        )
      ) : (
        <SvgIcon svgType={SVGType.SEMANTIC} name={"arrow up"} size={"small"} />
      )
    ) : (
      ""
    );

    return (
      <th
        key={column.key}
        onClick={() => isSortable && handleSortColumn(column.sortKey || "")}
        className={isSortable ? "cursor-pointer" : ""}
      >
        {column.header} {arrowIcon}
      </th>
    );
  };

  const MemoizedRow = ({ rowData, index }: any) => {
    return useMemo(
      () => (
        <tr key={index}>
          {columns.map((column) => (
            <td key={column.key}>
              {column.customValue
                ? column.customValue(rowData)
                : rowData[column.key]}
            </td>
          ))}
          {showActionButtonsConditionally && actionButtons && (
            <td key={`${index}-action`}>
              {actionButtons.map((action, actionIndex) => (
                <CustomButton
                  key={actionIndex}
                  type="button"
                  buttonText={action.label}
                  greyButton
                  transparent
                  handleClick={() => handleAction(action, rowData)}
                  // iconPosition={ICON_POSITION.RIGHT}
                  // iconProps={{
                  //   name: CUSTOM_SVG_ICON[action.icon] as string,
                  //   svgType: SVGType.CUSTOM,
                  //   size: "small",
                  //   baseclassname: `text-${action.iconColor}-color`,
                  // }}
                  baseclassname={"cursor-pointer"}
                />
              ))}
            </td>
          )}
        </tr>
      ),
      [rowData, columns]
    );
  };

  const renderData = (rowData: any, index: number) => {
    return <MemoizedRow rowData={rowData} index={index} />;
  };

  const sortedRecords = sortTable
    ? sortData(tableData || [], sortingCriteria)
    : tableData;

  return (
    <Fragment>
      <div
        className={classNames(["custom-table width-100 padding-2",  {
          "height-100": !(sortTable || serverSideSorting) || !hasPagination,
        } , baseClassName || ""])}
      >
        {children && <div className="custom-filter-container">{children}</div>}
        <div
          className={classNames(["custom-table-container width-100", {
            "height-100": !(sortTable || serverSideSorting) || !hasPagination,
          } , customTableClassName || ""])}
        >
          {fetching && <Loading />}
          {!fetching && (
            <table>
              <thead>
                <tr>
                  {columns?.map(renderColumnHeader)}
                  {showActionButtonsConditionally && actionButtons && (
                    <th>Action</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {sortedRecords?.length > 0 ? (
                  sortedRecords?.map(renderData)
                ) : (
                  <div className="text-5 flex flex-justify-center flex-align-center height-100 text-secondary-color width-100">
                    No Records Available!
                  </div>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {hasPagination && (
        <div className="custom-pagination-container width-100 ppt-padding-t-1">
          <div className="flex flex-row flex-justify-between padding-l-2 padding-r-2">
            <div>Page Size: 500</div>
            <div>Total: {paginationData?.totalItems || 0}</div>
          </div>
          <div className="flex flex-row">
            <Pagination
              activePage={currentPage}
              onPageChange={handlePaginationChange}
              totalPages={paginationData?.totalPages || 1}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CustomTable;
