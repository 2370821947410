import { combineReducers } from 'redux';
import { IRextReducer } from '../baseProvider/rext';
import {
  generatePptRext, generateTocRext, slidesFormRext,
  slidesListRext, templateFormRext, templateListRext,
  userListRext, layoutListRext,
  templateTypeListRext, templateTypeFormRext, layoutFormRext, imageFactoryListRext, imageFactoryManageRext,
  imageFactoryBulkUploadRext, imageTrainingListRext, manageUserRext, pngSvgRext, listTrainingQueueRext , trainedIdRext, logsRext,
  pptHistoryRext,
  pptReportsRext,
  analyticsDownloadRext,
  downloadLayoutCountRext,
  pptPromptRext,
  archiveAnalyticsRext
} from 'store/rext';
import modalState, { ModalState } from './modal';
import authReducer, { IAuthState } from './auth';

export interface ApplicationState {
  authState: IAuthState,
  modalState: ModalState;

  imageFactoryListState: IRextReducer;
  imageFactoryManageState: IRextReducer;
  imageFactoryBulkState: IRextReducer;
  imageTrainingListState: IRextReducer;

  userListState: IRextReducer;
  manageUserState: IRextReducer;

  getTocForPrompt: IRextReducer;
  getPptForPrompt: IRextReducer;
  getUserListState: IRextReducer;

  getTemplatesListState: IRextReducer;
  getTempTypeListState: IRextReducer;

  getLayoutsListState: IRextReducer;
  getSlidesListState: IRextReducer;

  getTemplateFormState: IRextReducer;
  getTempTypeFormState: IRextReducer;
  getSlideFormState: IRextReducer;
  getLayoutFormState: IRextReducer;


  pngSvgReportState: IRextReducer;
  listTrainingQueueState: IRextReducer;
  trainedIdState:IRextReducer;
  logsState:IRextReducer;

  pptReportsState: IRextReducer;
  pptHistoryState: IRextReducer;
  pptPromptState: IRextReducer;

  analyticsDownloadState: IRextReducer;
  downloadLayoutCountState: IRextReducer;
  archiveAnalyticsState :IRextReducer
}

const rootReducer = () =>
  combineReducers<ApplicationState>({
    authState: authReducer,
    modalState: modalState,
    imageFactoryListState: imageFactoryListRext.reducers,
    imageFactoryManageState: imageFactoryManageRext.reducers,
    imageFactoryBulkState: imageFactoryBulkUploadRext.reducers,
    imageTrainingListState: imageTrainingListRext.reducers,
    userListState: userListRext.reducers,
    manageUserState: manageUserRext.reducers,

    getTemplatesListState: templateListRext.reducers,
    getTempTypeListState: templateTypeListRext.reducers,
    getSlidesListState: slidesListRext.reducers,
    getTemplateFormState: templateFormRext.reducers,
    getLayoutsListState: layoutListRext.reducers,
    getSlideFormState: slidesFormRext.reducers,
    getTempTypeFormState: templateTypeFormRext.reducers,

    getTocForPrompt: generateTocRext.reducers,
    getPptForPrompt: generatePptRext.reducers,
    getUserListState: userListRext.reducers,
    getLayoutFormState: layoutFormRext.reducers,

    pngSvgReportState: pngSvgRext.reducers,
    listTrainingQueueState: listTrainingQueueRext.reducers,
    trainedIdState: trainedIdRext.reducers,
    logsState: logsRext.reducers,
    pptReportsState: pptReportsRext.reducers,
    pptHistoryState: pptHistoryRext.reducers,
    pptPromptState: pptPromptRext.reducers,
    analyticsDownloadState: analyticsDownloadRext.reducers,
    downloadLayoutCountState: downloadLayoutCountRext.reducers,
    archiveAnalyticsState : archiveAnalyticsRext.reducers
  });

export default rootReducer;
