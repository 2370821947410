// import { Fragment, useEffect, useMemo, useState } from "react";
// import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
// import CustomButton from "components/CustomButton";
// import { HookFormInput } from "components/FormInputs";
// import { requestPPTReports } from "store/rext";
// import { getPPTReportState } from "store/selectors";
// import DownloadLink from "components/Download/DownLoadLink";
// import CustomTable, { IActionButtons, IColumns, ISortingCriteria } from "components/CustomTableComponent/CustomTable";
// import { useNavigate } from "react-router-dom";
// import { URLRoutes } from "URLRoutes";
// import { convertDateToPST, transformCustomPPTCreated } from "./utils";
// import HookFormDatePicker from "components/FormInputs/HookFormDatePicker";

// interface IFilterValues {
//   startDate: string;
//   endDate: string;
//   userId: string;
// }

// const columns: IColumns[] = [
//   {
//     key: "userId",
//     header: "Customer Id",
//     sort: true,
//     sortKey: "userId",
//   },
//   {
//     key: "prompt",
//     header: "PPTs Created",
//     customValue: (data: any) => transformCustomPPTCreated(data),
//     sort: false,
//     sortKey: "prompt",
//   },
//   {
//     key: "userFeedback",
//     header: "User Feedback",
//     sort: false,
//     sortKey: "userFeedback",
//   },
//   {
//     key: "hasError",
//     header: "hasError",
//     customValue: (data: any) => (data.hasError ? "YES" : ""),
//     sort: false,
//     sortKey: "hasError",
//   },
//   {
//     key: "createdAt",
//     header: "Time Stamp",
//     customValue: (data: any) => convertDateToPST(data.createdAt),
//     sort: true,
//     sortKey: "createdAt",
//   },
// ];

// function PPTReports() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { data: reportsData, fetching: reportsFetching } = useSelector(getPPTReportState);
//   const { paginationInfo, records } = reportsData;
//   const [currentPage, setCurrentPage] = useState<number>(paginationInfo?.currentPage || 0);
//   const [filterValues, setFilterValues] = useState<IFilterValues>();
//   const [sortingCriteria, setSortingCriteria] = useState<ISortingCriteria>({
//     sortBy: "userId",
//     sortOrder: "ASC",
//   });
//   const {
//     handleSubmit,
//     formState: { errors },
//     control,
//   } = useForm({});


//   const tomorrow = new Date();
//   tomorrow.setDate(tomorrow.getDate() + 1);

//   // Get today's date
//   const today = new Date();

//   const [startDate, setStartDate] = useState(tomorrow);
//   const [endDate, setEndDate] = useState(today);


//   const handleHistoryClick = (id: any) => {
//     window.open(`${window.origin}${URLRoutes.clients.pptHistory.replace(":id", id)}`, "_blank");
//   };

//   const actionButtons: IActionButtons[] = [
//     {
//       handleClick: (rowData: any) => handleHistoryClick(rowData._id),
//       label: "History",
//     },
//   ];

//   const handleOnSubmit = (data: any) => {
//     setFilterValues({
//       startDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0],
//   endDate: new Date().toISOString().split('T')[0],
//       userId: data.userId,
//     });
//     const transformedData = {
//       page: currentPage,
//       pageSize: 500,
//       startDate: `${data.startDate}T00:00:00-08:00`,
//       endDate: `${data.endDate}T23:59:59-08:00`,
//       userId: data.userId,
//       sort: sortingCriteria.sortBy,
//       sortOrder: sortingCriteria.sortOrder,
//     };
//     dispatch(requestPPTReports(transformedData));
//   };

  
//   const handlePaginationChange = (e: any, { activePage }: any) => {
//     setCurrentPage(activePage);
//     const updatedBody = {
//       ...filterValues,
//       sort: sortingCriteria.sortBy,
//       sortOrder: sortingCriteria.sortOrder,
//       pageSize: 500,
//       page: activePage - 1,
//     };
//     dispatch(requestPPTReports(updatedBody));
//   };

//   const handleSortChange = (criteria: ISortingCriteria) => {
//     setSortingCriteria(criteria);
//   };

//   useEffect(() => {
//     if(!filterValues) {
//     return () => {
//       reportsData.records = [];
//       reportsData.paginationInfo = {};
//     };
//   }
//   }, [reportsData]);

//   return (
//     <Fragment>
//       <CustomTable
//         tableData={records || []}
//         hasPagination
//         paginationData={paginationInfo}
//         currentPage={currentPage}
//         columns={columns}
//         fetching={reportsFetching || false}
//         handlePaginationChange={handlePaginationChange}
//         actionButtons={actionButtons}
//         sortTable
//         sortingCriteria={sortingCriteria}
//         onSortChange={handleSortChange}
//         showActionButtonsConditionally
//       >
//         <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-row flex-align-center flex-justify-between">
//           <HookFormInput control={control} errors={errors} type="date" name="startDate" id="startDate" label="Start Date" baseClassName="width-25 margin-l-3" />
//           <HookFormInput control={control} errors={errors} type="date" name="endDate" id="endDate" label="End Date" baseClassName="width-25 margin-l-3" />
          
//           <HookFormInput control={control} errors={errors} baseClassName="width-25 margin-l-3" label="User Id" id="userId" name="userId" />
//           <div className="padding-5">
//             <CustomButton buttonText="Search" type="submit" primaryButton round />
//           </div>
//         </form>
//       </CustomTable>
//       <div className="position-absolute" style={{ right: 0, bottom: "10px" }}>
//         <DownloadLink
//           url="/ppt/v1/analytics/downloadcsv"
//           fileName="ppt-report.csv"
//           body={{
//             startDate: filterValues?.startDate,
//             endDate: filterValues?.endDate,
//             userId: filterValues?.userId,
//             sort: sortingCriteria.sortBy,
//             sortOrder: sortingCriteria.sortOrder,
//           }}
//         />
//       </div>
//     </Fragment>
//   );
// }

// export default PPTReports;
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "components/CustomButton";
import { HookFormCheckbox, HookFormInput, HookFormSelect } from "components/FormInputs";
import { requestPPTReports } from "store/rext";
import { getPPTReportState } from "store/selectors";
import DownloadLink from "components/Download/DownLoadLink";
import CustomTable, { IActionButtons, IColumns, ISortingCriteria } from "components/CustomTableComponent/CustomTable";
import { useNavigate } from "react-router-dom";
import { URLRoutes } from "URLRoutes";
import { convertDateToPST, transformCustomPPTCreated } from "./utils";
import { appNameOptions } from "containers/SlidesContainer/utils";

interface IFilterValues {
  startDate: string;
  endDate: string;
  userId: string;
  addEventCounts: boolean;
  appName : string
}

const columns: IColumns[] = [
  {
    key: "userId",
    header: "Customer Id",
    sort: true,
    sortKey: "userId",
  },
  {
    key: "prompt",
    header: "PPTs Created",
    customValue: (data: any) => transformCustomPPTCreated(data),
    sort: false,
    sortKey: "prompt",
  },
  {
    key: "info",
    header: "Additional Info",
    sort: false,
    sortKey: "info",
  },
  {
    key: "addtionalInfoUrl",
    header: "Addition Info URL",
    customValue: (data: any) => (data.addtionalInfoUrl?.length ? <Fragment>YES <br/>
      <DownloadLink url={`/ppt/v1/info/${data._id}/info`}
        buttonText="Download Info"
        isUrlDownload
      /> 
    </Fragment> : ""),
    sort: false,
    sortKey: "addtionalInfoUrl",
  },
  {
    key: "sentimental",
    header: "Sentimental Info",
    customValue: (data: any) => 
      <DownloadLink url={`/ppt/v1/info/${data._id}/sentimental`}
        buttonText="Download Sentimental"
        isUrlDownload
        />
    ,
    sort: false,
    sortKey: "sentimental",
  },
  {
    key: "noOfSlides",
    header: "No of slides",
    sort: false,
    sortKey: "userId",
  },
  {
    key: "noOfSlidesBySystem",
    header: "Slides Provide By System",
    sort: false,
    sortKey: "noOfSlidesBySystem",
  },
  {
    key: "isCompleted",
    header: "Created",
    customValue: (data: any) => (data.isCompleted ? "YES" : ""),
    sort: false,
    sortKey: "isCompleted",
  },
  {
    key: "fileCreated",
    header: "Downloaded",
    customValue: (data: any) => ((data.fileCreated || data.fileCreateCount)? <Fragment>YES ({data.fileCreateCount || 1}) <br/><DownloadLink url={`/ppt/v1/pptdownload/${data._id}`}
      urlParams={{ execution_id: data._id }}
      fileName={`${data._id}.pptx`}
      isUrlDownload />  </Fragment> : ""),
    sort: false,
    sortKey: "downloaded",
  },
  {
    key: "templateType",
    header: "Template Type",
    sort: false,
    sortKey: "templateType",
  },
  {
    key: "appName",
    header: "App Name",
    sort: false,
    sortKey: "appName",
  },
  {
    key: "userFeedback",
    header: "User Feedback",
    sort: false,
    sortKey: "userFeedback",
  },
  {
    key: "language",
    header: "Language",
    sort: false,
    sortKey: "language",
  },
  {
    key: "navigateFromGPT",
    header: "Public GPT",
    sort: false,
    sortKey: "navigateFromGPT",
  },
  {
    key: "hasError",
    header: "hasError",
    sort: false,
    sortKey: "hasError",
  },
  {
    key: "createdAt",
    header: "Time Stamp",
    sort: true,
    sortKey: "createdAt",
  },
  {
    key: "TEMPLATE_CHANGE_STARTED",
    header: "Template Change",
    sort: false,
    sortKey: "templateChange",
  },
  {
    key: "COLOR_CHANGE_STARTED",
    header: "Color Change",
    sort: false,
    sortKey: "colorChange",
  },
  {
    key: "REGENERATE_STARTED",
    header: "Regenerate",
    sort: false,
    sortKey: "regenerate",
  },
  {
  key: "CREATE_NEW_SLIDE_STARTED",
  header: "Create New Slide",
  sort : false,
  sortKey: "createNewSlide",
},
  {
    key: "INSERT_NEW_SLIDE_STARTED",
    header: "Insert Slide",
    sort: false,
    sortKey: "insertSlide",
  },
  {
    key: "DELETE_SLIDE_START",
    header: "Delete Slide",
    sort: false,
    sortKey: "deleteSlide",
  },
  {
    key: "MOVE_SLIDE_START",
    header: "Move Slide",
    sort: false,
    sortKey: "moveSlide",
  },
  
];
const yesterday = new Date();
  const today = new Date();

  const yesterdayFormattedDate = yesterday.toISOString().split('T')[0];
  const todayFormattedDate = today.toISOString().split('T')[0];

const DEFAULT_VALUE : IFilterValues = {
  startDate: yesterdayFormattedDate,
  endDate: todayFormattedDate,
  userId: "",
  addEventCounts : false,
  appName : "all"
};
function PPTReports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: reportsData, fetching: reportsFetching } = useSelector(getPPTReportState);
  const { paginationInfo, records } = reportsData;
  const [currentPage, setCurrentPage] = useState<number>(paginationInfo?.currentPage || 0);
  const [filterValues, setFilterValues] = useState<IFilterValues>({
    startDate: `${DEFAULT_VALUE.startDate}`,
    endDate: `${DEFAULT_VALUE.endDate}`,
    userId: '',
    addEventCounts: false,
    appName: " "
  });
  const [sortingCriteria, setSortingCriteria] = useState<ISortingCriteria>({
    sortBy: "createdAt",
    sortOrder: "DESC",
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue ,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return reportsData || DEFAULT_VALUE;
    }, [reportsData]),
  });

  const handleHistoryClick = (id: any) => {
    window.open(`${window.origin}${URLRoutes.clients.pptHistory.replace(":id", id)}`, "_blank");
  };
  const handlePromptClick = (id: any) => {
    window.open(`${window.origin}${URLRoutes.clients.pptPrompt.replace(":id", id)}`, "_blank");
  };

  const actionButtons: IActionButtons[] = [
    {
      handleClick: (rowData: any) => handleHistoryClick(rowData._id),
      label: "History",
    },
    {
      handleClick: (rowData: any) => handlePromptClick(rowData._id),
      label: "Prompt",
    },
  ];

  const handleOnSubmit = (data: any) => {
    const { startDate, endDate } = data;
    setFilterValues({
      startDate: startDate,
      endDate: endDate,
      userId: data.userId,
      addEventCounts  : Boolean(data.addEventCounts),
      appName : data.appName
    });
    const transformedData = {
      page: currentPage,
      pageSize: 500,
      startDate: `${data.startDate}T00:00:00-08:00`,
      endDate: `${data.endDate}T23:59:59-08:00`,
      userId: data.userId,
      addEventCounts  : Boolean(data.addEventCounts),
      sort: sortingCriteria.sortBy,
      sortOrder: sortingCriteria.sortOrder,
      appName: data.appName
    };
    dispatch(requestPPTReports(transformedData));
  };

  const handlePaginationChange = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    const updatedBody = {
      ...filterValues,
      sort: sortingCriteria.sortBy,
      sortOrder: sortingCriteria.sortOrder,
      pageSize: 500,
      page: activePage - 1,
    };
    dispatch(requestPPTReports(updatedBody));
  };

  const handleSortChange = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
  };

  const handleFetchDataForSorting = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria)
    return dispatch(requestPPTReports({
      ...filterValues,
      page: currentPage,
      pageSize: 500,
      sort: criteria.sortBy,
      sortOrder: criteria.sortOrder,
    }));
  }

  useEffect(() => {
    if(!filterValues) {
      return () => {
        reportsData.records = [];
        reportsData.paginationInfo = {};
      };
    }
  }, [reportsData]);

  // useEffect(() => {
  //   // if (templateId) {
  //     reset(DEFAULT_VALUE || reportsData);
  //   // } else {
  //   //   reset(DEFAULT_VALUE);
  //   // }
  // }, [reset, reportsData]);


  useEffect(() => {
    dispatch(requestPPTReports({
      page: currentPage,
      pageSize: 500,
      startDate: `${DEFAULT_VALUE.startDate}T00:00:00-08:00`,
      endDate: `${DEFAULT_VALUE.endDate}T23:59:59-08:00`,
      userId: '',
      addEventCounts  : false,
      appName : " ",
      sort: sortingCriteria.sortBy,
      sortOrder: sortingCriteria.sortOrder,
    }));
  }, []); 

  const getColumns = () => {
    if (filterValues.addEventCounts) {
      return columns;
    } else {
      return columns.filter(col => !["COLOR_CHANGE_STARTED", "REGENERATE_STARTED", "TEMPLATE_CHANGE_STARTED", "CREATE_NEW_SLIDE_STARTED","INSERT_NEW_SLIDE_STARTED","DELETE_SLIDE_START","MOVE_SLIDE_START"].includes(col.key));
    }
  };

  return (
    <Fragment>
      <CustomTable
        tableData={records || []}
        hasPagination
        paginationData={paginationInfo}
        currentPage={currentPage}
        columns={getColumns()}
        fetching={reportsFetching || false}
        handlePaginationChange={handlePaginationChange}
        actionButtons={actionButtons}
        // sortTable
        sortingCriteria={sortingCriteria}
        onSortChange={handleSortChange}
        showActionButtonsConditionally
        serverSideSorting 
        fetchData={handleFetchDataForSorting}
      >
        <form onSubmit={handleSubmit(handleOnSubmit)} className="flex flex-row flex-align-center flex-justify-between">
          <HookFormInput 
            control={control} 
            errors={errors} 
            type="date" 
            name="startDate" 
            id="startDate" 
            label="Start Date" 
            baseClassName="width-15 margin-l-3" 
            defaultValue={DEFAULT_VALUE.startDate} 
            onChange={(event:any) => setValue('startDate', event.target.value)}
          />
          <HookFormInput 
            control={control} 
            errors={errors} 
            type="date" 
            name="endDate" 
            id="endDate" 
            label="End Date" 
            baseClassName="width-15 margin-l-3" 
            defaultValue={DEFAULT_VALUE.endDate} 
            onChange={(event:any) => setValue('endDate', event.target.value)}
          />
          <HookFormInput control={control} errors={errors} baseClassName="width-15 margin-l-3" label="User Id" id="userId" name="userId" />
          <HookFormCheckbox
            validation={{}}
            control={control}
            errors={errors}
            baseClassName="margin-l-3"
            label={"Add Event Counts"}
            id={"addEventCounts"}
            name={"addEventCounts"}
          />
          <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={appNameOptions}
                name={"appName"}
                label={"Choose App"}
                id={"appName"}
                baseClassName="width-15 margin-l-3"
              />
          <div className="padding-5">
            <CustomButton buttonText="Search" type="submit" primaryButton round />
          </div>
        </form>
      </CustomTable>
      <div className="position-absolute" style={{ right: 0, bottom: "10px" }}>
        <DownloadLink
          url="/ppt/v1/analytics/downloadcsv"
          fileName="ppt-report.csv"
          body={{
            startDate: `${filterValues?.startDate}T00:00:00-08:00`,
            endDate: `${filterValues?.endDate}T23:59:59-08:00`,
            userId: filterValues?.userId,
            addEventCounts: filterValues?.addEventCounts,
            appName : filterValues?.appName,
            sort: sortingCriteria.sortBy,
            sortOrder: sortingCriteria.sortOrder,
          }}
        />
      </div>
    </Fragment>
  );
}

export default PPTReports;
